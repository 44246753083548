import {request} from '../../util/Request'
import Error from "../../util/Error"

const OUTBOUND_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_OUTBOUND_ORDER_STATE = {
    supplier_carrier_id: null,
    supplier_compound_id: null,
    skip_dealer_confirmation: true,
    route_id: null,
    price_id: null,
    price_period_id: null,
    zone_id: null,
    loading_factors: 0,
    loading_factor_note: 0,
    document: {...OUTBOUND_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_OUTBOUND_ORDER_STATE
}

export default {
    data() {
        return {
            outboundSelectedOrders: [],
            groupedOutboundOrders: [],
            selectedOutboundOrderLength: 0,
            outboundLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                outboundCarrierSuppliers: [],
            },
        }
    },
    methods: {
        async handleOutboundAddClick() {
            try{
                const {data} = await request({
                    url: 'dropdowns/orders',
                    method: 'post',
                    data: {
                        orders: this.outboundSelectedOrders.map((item) => item.id)
                    }
                })

                const self = this
                const from_to_keys = []
                _.map(data, (item) => {
                    from_to_keys.push(`${item.dealer.id} ${item.supplier_compound.id} ${item.current_operation.to_location.id} ${item.dealer_location.id}`)
                })

                _.map(_.uniqBy(from_to_keys), (item, index) => {
                    const locations = item.split(' ')
                    const items = _.filter(data, (order) => (
                        order.dealer.id === locations[0] &&
                        order.supplier_compound.id === locations[1] &&
                        order.current_operation.to_location.id === locations[2] &&
                        order.dealer_location.id === locations[3]
                    ))

                    self.groupedOutboundOrders.push({
                        dealer: items[0].dealer,
                        supplier_compound: items[0].supplier_compound,
                        from_location: items[0].current_operation.to_location,
                        to_location: items[0].dealer_location,
                        zone: items[0].dealer_location.zip,
                        items: items,
                        models: _.uniqBy(items, 'model.id').map(i => i.model.id),
                        brands: _.uniqBy(items, 'brand.id').map(i => i.brand.id),
                    })

                    self.outboundLoadFormState.orders.push({
                        ids: items.map((order) => order.id), // OrderIds
                        client_id: items[0].client.id,
                        dealer_id: items[0].dealer.id,
                        supplier_compound_id: items[0].supplier_compound.id,
                        skip_dealer_confirmation: true,
                        supplier_carrier_id: null,
                        price_id: null,
                        price_period_id: null,
                        zone_id: null,
                        route_id: null,
                        loading_factors: 0,
                        errors: null,
                        loading_factor_note: null,
                        document: {...OUTBOUND_LOAD_DOCUMENT_STATE},
                        documents: [],
                    })
                })

                this.getOutboundOrderNearLocationSupplierCarrier()
                this.outboundLoadFormVisible = true
            } catch (error) {
                console.log(error)
                this.handleServerError(error)
            }
        },
        async handleOutboundLoadSubmit() {
            if(this.validateOutboundLoad())
               return;

            try {
                const response = await request({
                    url: 'outbound/loads/create',
                    method: 'post',
                    data: {...this.outboundLoadFormState},
                })

                this.loadCreateSuccess()
                this.handleOutboundLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        async getOutboundOrderNearLocationSupplierCarrier() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/dealer/location/carrier/suppliers',
                    method: "post",
                    data: {
                        orders: this.outboundSelectedOrders.map(item => item.id)
                    }
                })

                const {data} = response
                this.dropdowns.outboundCarrierSuppliers = data

                _.map(this.groupedOutboundOrders, (group, index) => {
                    const filtered =_.filter(data, (supplier) => (
                                _.filter(supplier.prices, ({route}) => (
                                    route.from_location.id === group.from_location.id &&
                                    _.filter(route.to_zone.zip_ranges, ({zip_from, zip_to}) => _.inRange(group.zone*1, zip_from*1, zip_to*1 + 1) ).length > 0
                                )
                            ).length > 0
                        )
                    )

                    if(filtered.length > 0) {
                        this.outboundLoadFormState.orders[index].supplier_carrier_id = filtered[0].id
                    }
                })
            } catch (e) {
                this.dropdowns.outboundCarrierSuppliers = []
            }
        },
        validateOutboundLoad() {
            let errors = false

            _.map(this.outboundLoadFormState.orders, (item, index) => {
                this.outboundLoadFormState.orders[index].errors = null;
                if(!item.supplier_carrier_id) {
                    errors = true
                    this.outboundLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing')+'<br>'
                }

                if(item.loading_factors <= 0) {
                    errors = true
                    this.outboundLoadFormState.orders[index].errors = this.outboundLoadFormState.orders[index].errors + this.$t('validation.loadingFactorInvalid')
                }
            })

            return errors;
        },
        handleOutboundRowSelection(item, rowIndex, event) {
            if (item.dealer && item.dealer_location && item.tr_status === 3 && item.tr_status_type === 'outbound'
                && item.client_tr_accept_at !== null && (item.load_status === 8 || item.load_status === 9)) {
                const index = _.findIndex(this.outboundSelectedOrders, {id: item.id});

                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.outboundSelectedOrders[index]
                    this.selectedOutboundOrderLength = this.selectedOutboundOrderLength - 1
                } else { // else add new
                    this.outboundSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedOutboundOrderLength = this.selectedOutboundOrderLength + 1
                }
            }

            this.outboundSelectedOrders = _.compact(this.outboundSelectedOrders)
        },
        handleOutboundLoadFormOperationClose(refreshList = true) {
            this.outboundLoadFormState = {...DEFAULT_FORM_STATE}
            this.outboundLoadFormState.orders.length = 0
            this.outboundLoadFormVisible = false
            this.groupedOutboundOrders.length = 0
            this.outboundSelectedOrders.length = 0
            this.selectedOutboundOrderLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})
            if(refreshList) {
                this.refreshList()
            }
        },
        handleOutboundSupplierChange(value, group, index) {
            const {from_location, brands: groupBrands, models: groupModels} = group
            const {prices} = _.find(this.dropdowns.outboundCarrierSuppliers, {id: value})

            this.outboundLoadFormState.orders[index].loading_factor_note = null
            this.outboundLoadFormState.orders[index].price_id = null

            const price = _.find(prices, ({brands, route, models}) => {
                let doesSelectedOrderContainsPriceModel = 0
                let doesSelectedOrderContainsPriceBrand = 0
                _.map(models, (m) => {
                    if (groupModels.includes(m.id)) {
                        doesSelectedOrderContainsPriceModel++;
                    }
                })

                _.map(brands, (m) => {
                    if (groupBrands.includes(m.id)) {
                        doesSelectedOrderContainsPriceBrand++;
                    }
                })

                return (
                    route.from_location.id === from_location.id &&
                    _.filter(route.to_zone.zip_ranges, ({zip_from, zip_to}) => _.inRange(group.zone, zip_from, zip_to*1+1 ) ).length > 0 &&
                    groupModels.length === doesSelectedOrderContainsPriceModel &&
                    groupBrands.length === doesSelectedOrderContainsPriceBrand
                )
            });

            if(price) {
                this.outboundLoadFormState.orders[index].route_id = price.route.id
                this.outboundLoadFormState.orders[index].price_id = price.id
                this.outboundLoadFormState.orders[index].price_period_id = price.price_periods[0].id
                this.outboundLoadFormState.orders[index].zone_id = (price.route.to_zone || {}).id
                this.outboundLoadFormState.orders[index].loading_factors = price.loading_factors
                if(price.loading_factors > 0 && group.items.length > price.loading_factors) {
                    this.outboundLoadFormState.orders[index].loading_factor_note = (Math.ceil(group.items.length/price.loading_factors))+' '+this.$t('msc.loadsWillBeCreated')
                }
            } else {
                this.outboundLoadFormState.orders[index].price_id = null
                this.priceNotFound()
            }
        },
        handleAddUpdateOutboundLoadDocumentClick(groupIndex) {
            this.outboundLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.outboundLoadFormState.orders[groupIndex].document.document || this.outboundLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.outboundLoadFormState.orders[groupIndex].document.document.id) {
                this.outboundLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.outboundLoadFormState.orders[groupIndex].document.title || _.trim(this.outboundLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.outboundLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.outboundLoadFormState.orders[groupIndex].documents, (document) => {
                if(document.token !== this.outboundLoadFormState.orders[groupIndex].document.token) {
                    if(document.title === this.outboundLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.outboundLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.outboundLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.outboundLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.outboundLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.outboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.outboundLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.outboundLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.outboundLoadFormState.orders[groupIndex].document = {...OUTBOUND_LOAD_DOCUMENT_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteOutboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.outboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.outboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.outboundLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditOutboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.outboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.outboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.outboundLoadFormState.orders[groupIndex].document = {...this.outboundLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetOutboundLoadDocument(groupIndex) {
            this.outboundLoadFormState.orders[groupIndex].document = {...OUTBOUND_LOAD_DOCUMENT_STATE}
            this.outboundLoadFormState.orders[groupIndex].document.error = false;
        },
    },
}
